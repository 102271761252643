import React, { useState, useEffect } from 'react';

type TenantContextType = {
  logoName: string;
  brandName: string;
  brandBlogUrl: string;
};

export const TenantContext = React.createContext<TenantContextType | undefined>(
  undefined,
);

const totalHipaaConfig = {
  logoName: 'TotalHipaa',
  brandName: 'Total HIPAA',
  reportName: 'Risk Assessment Report',
  reportDefaultLogo: 'default/total-hipaa-logo.png',
  brandBlogUrl: 'https://www.totalhipaa.com/',
};

const totalCyberComplianceConfig = {
  logoName: 'TotalCyberCompliance',
  brandName: 'Total Cyber Compliance',
  reportName: 'Gap Analysis',
  reportDefaultLogo: 'default/total-cyber-compliance-logo.png',
  brandBlogUrl: 'https://totalcmmc.com/',
};

// TODO: Move to useReducer
export const TenantProvider = ({ children }: { children: React.ReactNode }) => {
  const [logoName, setLogoName] = useState<string>(totalHipaaConfig.logoName);
  const [brandName, setBrandName] = useState<string>(
    totalHipaaConfig.brandName,
  );
  const [brandBlogUrl, setBrandBlogUrl] = useState<string>(
    totalHipaaConfig.brandBlogUrl,
  );

  useEffect(() => {
    if (
      process.env.tentantid &&
      process.env.tentantid === 'totalcybercompliance'
    ) {
      setLogoName(totalCyberComplianceConfig.logoName);
      setBrandName(totalCyberComplianceConfig.brandName);
      setBrandBlogUrl(totalCyberComplianceConfig.brandBlogUrl);
    }
  }, []);

  return (
    <TenantContext.Provider value={{ logoName, brandName, brandBlogUrl }}>
      {children}
    </TenantContext.Provider>
  );
};
