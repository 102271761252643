import { call, take, put, delay } from 'redux-saga/effects';
import {
  NotificationsPollingStartAction,
  NOTIFICATIONS_POLLING_START,
} from 'src/store/types';
import actions from 'src/store/actions';

const pollNotifications = function* ({
  userId,
}: NotificationsPollingStartAction): Generator {
  while (true) {
    yield put(actions.notificationListingLoadRequest({ userId }));
    // Poll every 15 minutes
    yield delay(900000);
  }
};

export default function* (): Generator {
  while (true) {
    const action = yield take(NOTIFICATIONS_POLLING_START);
    yield call(pollNotifications, action as NotificationsPollingStartAction);
  }
}
