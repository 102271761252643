/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSelector } from 'reselect';
import {
  AssessmentState,
  CompanyObject,
  RootState,
  CompanyState,
  AssessmentTypeListingIndexedItem,
  Loader,
} from '../types';
import { AssessmentListingIndexedInterface } from '../types/assessmentListing';
import { getAssessmentsFromState } from './assessment';
import { getAssessmentTypes } from './assessmentType';
import { getCompanies } from './companyListing';

export const getIndexAssessmentsByCompany = createSelector(
  (state: RootState) => getAssessmentsFromState(state),
  (state: RootState) => getAssessmentTypes(state),
  (state: RootState) => getCompanies(state),
  (
    assessments: AssessmentState[],
    assessmentTypes: AssessmentTypeListingIndexedItem,
    companies: CompanyState[],
  ) => {
    const indexedCompanies: AssessmentListingIndexedInterface =
      companies.reduce(
        (acc: AssessmentListingIndexedInterface, company: CompanyState) => {
          acc[company.name] = {
            name: company.name,
            company: company,
            assessments: [],
          };
          return acc;
        },
        {} as AssessmentListingIndexedInterface,
      );

    assessments.forEach((assessment) => {
      const company = companies.find((c) => c.id === assessment.companyId);
      if (company) {
        if (indexedCompanies[company.name]) {
          indexedCompanies[company.name].assessments.push({
            ...assessment,
            assessmentType: assessmentTypes[assessment.typeId],
          });
        } else {
          indexedCompanies[company.name] = {
            name: company.name,
            assessments: [
              {
                ...assessment,
                assessmentType: assessmentTypes[assessment.typeId],
              },
            ],
          } as CompanyObject;
        }
        indexedCompanies[company.name].company = company;
      }
    });
    return indexedCompanies;
  },
);

export const getAssessmentListingLoader = (state: RootState): Loader => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return state.assessmentListing.loader;
};
