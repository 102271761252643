import { createSelector } from 'reselect';
import { Loader, RootState, User, UserListingIndexedItem } from '../types';

export const getUsersFromState = (state: RootState): User[] => {
  const users: User[] = Object.values(state.userListing.users);
  return users;
};

export const getUsers = createSelector(
  (state: RootState) => getUsersFromState(state),
  (state: RootState) => state.userListing.totalItems,
  (users: User[], totalItems: number) => {
    const indexedUsers: UserListingIndexedItem = users.reduce(
      (users: UserListingIndexedItem, user: User) => {
        return {
          ...users,
          [user.id]: user,
        };
      },
      {},
    );

    return { data: indexedUsers, totalItems };
  },
);

export const getUserListingLoader = (state: RootState): Loader => {
  return state.userListing.loader;
};

export const getSelectedUser = createSelector(
  (state: RootState) => state.userListing.users,
  (_: RootState, id: number | undefined) => id ?? 0,
  (indexedUsers: UserListingIndexedItem, id: number) => indexedUsers[id],
);
