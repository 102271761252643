import { call, takeEvery, put } from 'redux-saga/effects';
import {
  NotificationListingLoadRequestAction,
  NotificationsReadRequestAction,
  NOTIFICATIONS_LOAD_REQUEST,
  NOTIFICATIONS_READ_REQUEST,
} from 'src/store/types/notificationListing';
import {
  NotificationFromResponse,
  NotificationUpdateRequestAction,
  NOTIFICATION_UPDATE_REQUEST,
  PaginatedNotifications,
} from 'src/store/types/notification';
import actions from 'src/store/actions';
import { api } from 'src/api/services/ThApi';

export const loadNotifications = function* async({
  request,
}: NotificationListingLoadRequestAction): Generator {
  try {
    const notifications = yield call(api.getNotificationsByUserId, request);
    yield put(
      actions.notificationListingLoadSuccess(
        notifications as PaginatedNotifications,
      ),
    );
  } catch (err) {
    yield put(
      actions.notificationListingLoadError(
        'Failed to load notifications',
        err as Error,
      ),
    );
  }
};

export const markNotificationAsRead = function* async({
  ids,
}: NotificationsReadRequestAction): Generator {
  try {
    yield call(api.updatesNotificationListingAsRead, ids);
    yield put(actions.notificationsMarkAsReadSuccess());
  } catch (err) {
    yield put(
      actions.notificationsMarkAsReadError(
        'Failed to update notification',
        err as Error,
      ),
    );
  }
};

export const updateNotification = function* async({
  notification,
}: NotificationUpdateRequestAction): Generator {
  try {
    const notificationFromResponse = yield call(
      api.updateNotification,
      notification,
    );
    yield put(
      actions.notificationUpdateSuccessAction(
        notificationFromResponse as NotificationFromResponse,
      ),
    );
  } catch (err) {
    yield put(
      actions.notificationUpdateErrorAction(
        'Failed to update notification',
        err as Error,
      ),
    );
  }
};

export default function* (): Generator {
  return [
    yield takeEvery(NOTIFICATIONS_LOAD_REQUEST, loadNotifications),
    yield takeEvery(NOTIFICATIONS_READ_REQUEST, markNotificationAsRead),
    yield takeEvery(NOTIFICATION_UPDATE_REQUEST, updateNotification),
  ];
}
