/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { createSelector } from 'reselect';
import {
  CompanyListingIndexedItem,
  CompanyListingState,
  CompanyState,
  Loader,
  RootState,
} from '../types';

const getIndexedCompaniesFromState = (
  state: RootState,
): CompanyListingIndexedItem => {
  return state.companyListing.companies;
};

export const getCompanyListingFromState = (
  state: RootState,
): CompanyListingState => state.companyListing;

const getCompaniesFromState = (state: RootState): CompanyState[] => {
  return Object.values(getIndexedCompaniesFromState(state));
};

export const getCompaniesLoader = (state: RootState): Loader => {
  return state.companyListing.loader;
};

export const getCompanies = createSelector(
  (state: RootState) => getCompaniesFromState(state),
  (companies: CompanyState[]) => companies,
);

export const getCompanyById = createSelector(
  (state: RootState) => getIndexedCompaniesFromState(state),
  (_: RootState, id: number) => id,
  (companyListingIndexedItem: CompanyListingIndexedItem, id: number) => {
    return companyListingIndexedItem[id];
  },
);
