import {
  NOTIFICATIONS_LOAD_SUCCESS,
  NOTIFICATIONS_LOAD_REQUEST,
  NOTIFICATION_UPDATE_SUCCESS,
  NOTIFICATION_UPDATE_REQUEST,
  NOTIFICATION_UPDATE_ERROR,
  Loader,
  NotificationListingState,
  NotificationListingActionTypes,
  NotificationActionTypes,
  NotificationUpdateSuccessAction,
} from 'src/store/types';

const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

const notificationListingInitialState: NotificationListingState = {
  notifications: {
    page: 1,
    pageSize: 20,
    total: 0,
    records: [],
  },
  loader: loaderInitialState,
};

export const notificationListingReducer = (
  state = notificationListingInitialState,
  action: NotificationListingActionTypes | NotificationActionTypes,
): NotificationListingState => {
  switch (action.type) {
    case NOTIFICATION_UPDATE_REQUEST:
    case NOTIFICATIONS_LOAD_REQUEST: {
      return {
        ...state,
        loader: { ...loaderInitialState, loading: true },
      };
    }
    case NOTIFICATIONS_LOAD_SUCCESS: {
      if (action.notifications) {
        return {
          notifications: action.notifications,
          loader: { ...loaderInitialState, completed: true, loading: false },
        };
      }
    }
    case NOTIFICATION_UPDATE_SUCCESS: {
      return {
        notifications: {
          ...state.notifications,
          records: state.notifications.records.map((n) => {
            const actionUpdate = action as NotificationUpdateSuccessAction;
            return n.id === actionUpdate.notification.id
              ? {
                  ...n,
                  ...actionUpdate.notification,
                }
              : n;
          }),
        },
        loader: { ...loaderInitialState, completed: true, loading: false },
      };
    }
    case NOTIFICATION_UPDATE_ERROR: {
      return {
        ...state,
        loader: {
          ...loaderInitialState,
          completed: false,
          error: action.message.toString(),
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};
