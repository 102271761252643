export interface Palette {
  astral: {
    main: string;
  };
  bostonBlue: {
    main: string;
  };
  esmerald: {
    main: string;
  };
  amazon: {
    main: string;
  };
  chathamsBlue: {
    main: string;
    secondary: string;
  };
  mariner: {
    main: string;
  };
  concrete: {
    main: string;
  };
  athensGray: {
    main: string;
  };
  steelBlue: {
    main: string;
    darker: string;
  };
  nepal: {
    main: string;
  };
  twilightBlue: {
    main: string;
  };
  cornflowerBlue: {
    main: string;
  };
  glacier: {
    main: string;
  };
  // OLD
  red: {
    main: string;
    lighter: string;
    light: string;
    litest: string;
    darker: string;
  };
  lightBlue: {
    main: string;
  };
  darkBlue: {
    main: string;
  };
  deepBlue: {
    main: string;
  };
  green: {
    darkest: string;
    darker: string;
    main: string;
    lighter: string;
    light: string;
    lightest: string;
  };
  grayscale: {
    white: string;
    lightGray: string;
    moonGray: string;
    middleGray: string;
    darkGray: string;
    preDark: string;
  };
  paleYellow: {
    main: string;
    darker: string;
  };
  orange: {
    main: string;
  };
  mandy: {
    main: string;
    lighter: string;
  };
  white: {
    main: string;
  };
  black: {
    main: string;
    mainRgbLessOpacity: string;
    shark: string;
  };
  alto: {
    main: string;
    secondary: string;
  };
  morningGlory: {
    main: string;
  };
  zircon: {
    main: string;
  };
  iceberg: {
    main: string;
  };
  alabaster: {
    main: string;
  };
  burntSienna: {
    main: string;
  };
  sunglo: {
    main: string;
  };
  gallery: {
    main: string;
  };
}

export const basePalette: Palette = {
  astral: {
    main: '#337D98',
  },
  bostonBlue: {
    main: '#3C95B3',
  },
  esmerald: {
    main: '#59CC8E',
  },
  amazon: {
    main: '#39835C',
  },
  chathamsBlue: {
    main: '#1A4B71',
    secondary: '#16527E',
  },
  mariner: {
    main: '#2C76B7',
  },
  concrete: {
    main: '#F3F3F3',
  },
  athensGray: {
    main: '#F4F5F8',
  },
  steelBlue: {
    main: '#4586BF',
    darker: '#417BAE',
  },
  nepal: {
    main: '#91BAC8',
  },
  twilightBlue: {
    main: '#F4FCFF',
  },
  red: {
    main: '#F18270',
    lighter: '#F49D98',
    light: '#F4BDBD',
    litest: '#F7E0DF',
    darker: '#FF4d4F',
  },
  mandy: {
    main: '#E84F4F',
    lighter: '#E69A9A',
  },
  white: {
    main: '#FFFFFF',
  },
  cornflowerBlue: {
    main: '#6195ED',
  },
  black: {
    main: '#000000',
    mainRgbLessOpacity: 'rgba(0, 0, 0, 0.10)',
    shark: '#1C2428',
  },
  alto: {
    main: '#CECECE',
    secondary: '#DADADA',
  },
  morningGlory: {
    main: '#98BDDC',
  },
  zircon: {
    main: '#EEF4FF',
  },
  iceberg: {
    main: '#D3F4F4',
  },
  grayscale: {
    white: '#FFFFFF',
    lightGray: '#F3F3F3',
    moonGray: '#E4E4E4',
    middleGray: '#A5A5A5',
    darkGray: '#2E2E2E',
    preDark: '#6A6A6A',
  },
  alabaster: {
    main: '#F9F9F9',
  },
  burntSienna: {
    main: '#EF5454',
  },
  sunglo: {
    main: '#E56F6F',
  },
  gallery: {
    main: '#F0F0F0',
  },
  glacier: {
    main: '#77C1C1',
  },
  // OLD
  lightBlue: {
    main: '#76A5DD',
  },
  darkBlue: {
    main: '#1B75B7',
  },
  deepBlue: {
    main: '#0E3F5F',
  },
  green: {
    darkest: '#008B43',
    darker: '#25BA6E',
    main: '#3ace8e',
    lighter: '#43cc8f',
    light: '#97E0BC',
    lightest: '#D6F2E2',
  },
  paleYellow: {
    main: '#FFFEEA',
    darker: '#FFFB8F',
  },
  orange: {
    main: '#FCDDB6',
  },
};
