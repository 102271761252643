/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import download from 'downloadjs';
import { Moment } from 'moment';
import s3 from 'src/services/downloadFile';
import { HttpClient } from './HttpClient';
import {
  AnswerFromResponse,
  AnswerList,
  AssessmentFromResponse,
  AssessmentInput,
  AssessmentQueryParams,
  AssessmentRiskAnalysisFromResponse,
  AssessmentState,
  AssessmentTypeFromResponse,
  AssessmentTypesDelegateFromResponse,
  AssessmentTypesLabelFromResponse,
  AssigneeFromResponse,
  CategoryFromResponse,
  CategoryInput,
  Comment,
  CompanyFromResponse,
  CompanyInput,
  CompanyQueryOptions,
  CompanyState,
  ConstantState,
  DocumentDraftFromResponse,
  DocumentDraftInput,
  DocumentDraftState,
  DocumentFromResponse,
  DocumentState,
  DocumentStatus,
  DocumentType,
  FeatureFlagResponse,
  FormInput,
  FormMetadataState,
  FormQuestionsState,
  FormState,
  InterviewFromResponse,
  InterviewMetadataState,
  InterviewState,
  MultipleUsersCreationResponse,
  NotificationFromResponse,
  NotificationUpdateParams,
  PaginatedNotificationRequest,
  PaginatedNotifications,
  Question,
  QuestionType,
  TemplateInput,
  TemplateState,
  Thread,
  Trainee,
  TypeOfTraining,
  User,
  UserFromResponse,
  UserInput,
  UserOnboardingFromResponse,
  UserUpdateInput,
  VariableInput,
  VariableState,
} from '../../store/types';
import { AuthMethod } from '../../types/common';

class ThApi extends HttpClient {
  public constructor() {
    super(process.env.REACT_APP_API_URL as string);
  }

  public login = async (email: string, password: string): Promise<User> => {
    const res = await this.instance.post<{ data: User }>(
      '/login',
      { email, password },
      { withCredentials: true },
    );
    return res.data;
  };

  public impersonate = async (email: string): Promise<User> => {
    const res = await this.instance.post<{ data: User }>(
      '/impersonate',
      { email },
      { withCredentials: true },
    );
    return res.data;
  };

  public refreshToken = async (): Promise<void> => {
    const res = await this.instance.post(
      '/refresh-token',
      {},
      { withCredentials: true },
    );
    return res;
  };

  public logout = async (): Promise<User> => {
    const res = await this.instance.post<{ data: User; message: string }>(
      '/logout',
      {},
      { withCredentials: true },
    );
    return res.data;
  };

  public getUserDetails = async (): Promise<User> => {
    const res = await this.instance.get<{ data: User }>('/users/me', {
      withCredentials: true,
    });
    return res.data;
  };

  public getUserDetailsById = async (id: number): Promise<User> => {
    const res = await this.instance.get<{ data: User }>(`/users/${id}`, {
      withCredentials: true,
    });
    return res.data;
  };

  public getFormList = async (
    withAssessmentType = false,
  ): Promise<FormState[]> => {
    const res = await this.instance.get<{ data: FormState[] }>(
      `/forms?withAssessmentType=${withAssessmentType}`,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public saveForm = async (
    formData: Partial<FormState>,
  ): Promise<FormState> => {
    const res = await this.instance.post<{ data: FormState }>(
      '/forms',
      formData,
      { withCredentials: true },
    );
    return res.data;
  };

  public updateForm = async (
    form: FormState,
    questions?: Question[],
    metadata?: FormMetadataState,
  ): Promise<{ form: FormState; questions: Question[] }> => {
    const res = await this.instance.put<{
      data: { form: FormState; questions: Question[] };
    }>(
      `/forms/${form.id}`,
      { form, questions, metadata },
      { withCredentials: true },
    );
    return res.data;
  };

  public updateFormStatus = async (
    formId: number,
    status: boolean,
  ): Promise<{ form: FormState }> => {
    const res = await this.instance.put<{ data: { form: FormState } }>(
      `/forms/${formId}`,
      { form: { isActive: status } },
      { withCredentials: true },
    );
    return res.data;
  };

  public cloneForm = async (formId: number) => {
    await this.instance.post(
      `/forms/${formId}/clone`,
      {},
      { withCredentials: true },
    );
  };

  public deleteForm = async (formId: number) => {
    await this.instance.delete(`/forms/${formId}`, { withCredentials: true });
  };

  public getQuestionTypes = async (): Promise<QuestionType[]> => {
    const res = await this.instance.get<{ data: QuestionType[] }>(
      '/question_types',
      { withCredentials: true },
    );
    return res.data;
  };

  public loadQuestions = async (
    formId: number,
    assessmentId?: number,
  ): Promise<Question[]> => {
    const url = `/questions?formId=${formId}`;
    const res = await this.instance.get<{ data: Question[] }>(
      assessmentId ? `${url}&assessmentId=${assessmentId}` : url,
      { withCredentials: true },
    );
    return res.data;
  };

  public getCompany = async (
    companyId: number,
    options?: CompanyQueryOptions,
  ): Promise<CompanyState> => {
    const {
      withAssessments = true,
      withUsers = true,
      withTrainingType = true,
    } = options ?? {};
    const url = `/companies/${companyId}`;
    let queryParams = '';
    if (withAssessments) {
      queryParams += '&withAssessments=true';
    }
    if (withUsers) {
      queryParams += '&withUsers=true';
    }
    if (withTrainingType) {
      queryParams += '&withTrainingType=true';
    }
    const res = await this.instance.get<{ data: CompanyState }>(
      `${url}?${queryParams}`,
      { withCredentials: true },
    );
    return res.data;
  };

  public saveInterview = async (
    interviewData: Partial<InterviewState>,
  ): Promise<InterviewState> => {
    const res = await this.instance.post<{ data: InterviewState }>(
      '/interviews',
      interviewData,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public updateInterview = async (
    interview: InterviewState,
    answers: AnswerList,
    metadata: InterviewMetadataState,
  ): Promise<{ interview: InterviewState }> => {
    const res = await this.instance.put<{
      data: { interview: InterviewState };
    }>(
      `/interviews/${interview.id}`,
      { interview, answers, metadata },
      { withCredentials: true },
    );
    return res.data;
  };

  public getInterviewAnswers = async (
    interviewId: number,
  ): Promise<AnswerFromResponse[]> => {
    const res = await this.instance.get<{ data: AnswerFromResponse[] }>(
      `/interviews/${interviewId}/answers`,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public getInterviewReconciliation = async (
    fromDate: Moment,
    toDate: Moment,
    email: string,
    page: number,
    pageSize: number,
  ): Promise<AnswerFromResponse[]> => {
    const res = await this.instance.get<{ data: AnswerFromResponse[] }>(
      `/activity-logs?from=${fromDate.format('YYYY-MM-DD 00:00:00')}&to=${toDate.format(
        'YYYY-MM-DD 23:59:59',
      )}&email=${encodeURIComponent(email)}&subjects=Interview&page=${page}&take=${pageSize}`,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public getAnswerReconciliation = async (
    interviewId: number,
    fromDate: Moment,
    toDate: Moment,
    email: string,
    page: number,
    pageSize: number,
  ): Promise<AnswerFromResponse[]> => {
    const res = await this.instance.get<{ data: AnswerFromResponse[] }>(
      `/activity-logs?from=${fromDate.format('YYYY-MM-DD 00:00:00')}&to=${toDate.format(
        'YYYY-MM-DD 23:59:59',
      )}&email=${encodeURIComponent(
        email,
      )}&subjects=Answer,Assignee&page=${page}&take=${pageSize}&interviewId=${interviewId}`,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public getCompanyList = async (
    typeOfTrainings: null | TypeOfTraining[],
    active: boolean,
  ): Promise<CompanyState[]> => {
    let url = '/companies/detail';
    if (active) {
      url += '?active=true';
    }
    const res = await this.instance.get<{ data: CompanyState[] }>(url, {
      withCredentials: true,
      params: { typeOfTrainings },
    });
    return res.data;
  };

  public getPaginatedCompanies = async (
    pageNumber = 1,
    pageSize = 20,
    active: boolean,
    name = '',
  ): Promise<CompanyState[]> => {
    let url = '/companies/';
    if (active) {
      url += `?active=${active}&page=${pageNumber}&pageSize=${pageSize}&name=${name}`;
    }
    const res = await this.instance.get<{ data: CompanyState[] }>(url, {
      withCredentials: true,
    });
    return res.data;
  };

  public getAllFeatureFlags = async (): Promise<FeatureFlagResponse[]> => {
    const res = await this.instance.get<{ data: FeatureFlagResponse[] }>(
      `/feature-flags`,
    );
    return res.data;
  };

  public getAllAssessmentTypes = async (): Promise<
    AssessmentTypeFromResponse[]
  > => {
    const res = await this.instance.get<{ data: AssessmentTypeFromResponse[] }>(
      `/assessment_types`,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public updateAssessment = async (
    assessment: AssessmentState,
  ): Promise<AssessmentFromResponse> => {
    const res = await this.instance.put<{ data: AssessmentFromResponse }>(
      `/assessments/${assessment.id}`,
      assessment,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public completeAssessment = async (assessmentId: number) => {
    await this.instance.post(
      `/assessments/${assessmentId}/complete`,
      {},
      { withCredentials: true },
    );
  };

  public loadAssessment = async (
    assessmentId: number,
    options: AssessmentQueryParams = { withType: true },
  ): Promise<AssessmentFromResponse> => {
    let queryParams = '';

    if (options.withType) {
      queryParams += '&withType=true';
    }

    if (options.withCompany) {
      queryParams += '&withCompany=true';
    }

    if (options.withForms) {
      queryParams += '&withForms=true';
    }

    const res = await this.instance.get<{ data: AssessmentFromResponse }>(
      `/assessments/${assessmentId}?${queryParams}`,
      { withCredentials: true },
    );

    return res.data;
  };

  public loadAssessmentSuggestions = async (
    assessmentId: number,
    excludeInterviewId?: number,
  ): Promise<{ [k: string]: string[] }> => {
    const queryParams = excludeInterviewId
      ? `?excludeInterviews=${excludeInterviewId}`
      : '';

    const res = await this.instance.get<{ data: { [k: string]: string[] } }>(
      `/assessments/${assessmentId}/suggestions${queryParams}`,
      { withCredentials: true },
    );

    return res.data;
  };

  public getUploadUrl = async (key: string, contentType: string) => {
    const res = await this.instance.get<{ url: string }>('/utils/uploadUrl', {
      withCredentials: true,
      params: { key, contentType },
    });
    return res.url;
  };

  public getInterviewCsv = async (interviewId: number) => {
    const res = await this.instance.get(`/interviews/${interviewId}/csv`, {
      withCredentials: true,
    });
    const data = new Blob([res]);
    return download(data, `${interviewId}.csv`, 'text/csv');
  };

  public getAssessmentTypeContentsAsJson = async (assessmentTypeId: number) => {
    const res = await this.instance.get(
      `assessment_types/${assessmentTypeId}/contents/json`,
      {
        withCredentials: true,
      },
    );
    return download(
      JSON.stringify(res, null, '\t'),
      `Assessment-${assessmentTypeId}.json`,
      'text/json',
    );
  };

  public getAssessmentCsv = async (assessmentId: number) => {
    const res = await this.instance.get(`/assessments/${assessmentId}/csv`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/zip',
      },
      withCredentials: true,
    });

    const blob = new Blob([res], { type: 'application/octet-stream' });
    return download(blob, `${assessmentId.toString()}.zip`);
  };

  public getDocumentFiles = async (documentId: number) => {
    const res = await this.instance.get(
      `/documents/${documentId}/downloadables`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/zip',
        },
        withCredentials: true,
      },
    );

    const blob = new Blob([res], { type: 'application/octet-stream' });
    return download(blob, `document_${documentId.toString()}.zip`);
  };

  public getDocumentsByAssessment = async (
    assessmentId: number,
    documentType?: DocumentType,
  ): Promise<DocumentFromResponse[]> => {
    const res = await this.instance.get<{ data: DocumentFromResponse[] }>(
      '/documents',
      {
        withCredentials: true,
        params: {
          assessmentId,
          documentType,
        },
      },
    );

    return res.data;
  };

  public setDocumentInClientReview = async (
    documentId: number,
  ): Promise<DocumentFromResponse> => {
    const res = await this.instance.put<{ data: DocumentFromResponse }>(
      `/documents/${documentId}`,
      { status: DocumentStatus.CLIENT_REVIEW },
      { withCredentials: true },
    );
    return res.data;
  };

  public approveDocument = async (
    documentId: number,
  ): Promise<DocumentFromResponse> => {
    const res = await this.instance.put<{ data: DocumentFromResponse }>(
      `/documents/${documentId}`,
      { status: DocumentStatus.APPROVED },
      { withCredentials: true },
    );
    return res.data;
  };

  public acceptDocument = async (
    documentId: number,
  ): Promise<DocumentFromResponse> => {
    const res = await this.instance.put<{ data: DocumentFromResponse }>(
      `/documents/${documentId}`,
      { status: DocumentStatus.ACCEPTED },
      { withCredentials: true },
    );
    return res.data;
  };

  public closeDocument = async (
    documentId: number,
  ): Promise<DocumentFromResponse> => {
    const res = await this.instance.put<{ data: DocumentFromResponse }>(
      `/documents/${documentId}`,
      { status: DocumentStatus.CLOSED },
      { withCredentials: true },
    );
    return res.data;
  };

  public getDocument = async (
    documentId: number,
  ): Promise<DocumentFromResponse> => {
    const res = await this.instance.get<{ data: DocumentFromResponse }>(
      `/documents/${documentId}`,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public getDocumentRawFile = async (documentId: number) => {
    const res = await this.instance.get<{ data: DocumentFromResponse }>(
      `/documents/${documentId}`,
      {
        withCredentials: true,
      },
    );

    return download(
      JSON.stringify(res),
      `document_${documentId.toString()}.json`,
      'application/json',
    );
  };

  public saveDocumentPP = async (
    documentDraft: DocumentDraftState,
    assessmentId: number,
  ): Promise<DocumentState> => {
    const res = await this.instance.post<{ data: DocumentState }>(
      `/assessments/${assessmentId}/documents/pp`,
      { documentDraftId: documentDraft.id },
      { withCredentials: true },
    );

    const file = JSON.parse(res.data.file as string) as {
      Key: string;
      Bucket: string;
      'Content-Type': string;
    };
    await s3.handleFileDownload(file.Key, `${res.data.name}.pdf`, file.Bucket);

    return res.data;
  };

  public getThreadsWithComments = async ({
    assessmentId,
    documentDraftId,
  }: {
    assessmentId?: number;
    documentDraftId?: number;
  }): Promise<Thread[]> => {
    let query =
      '/threads?withComments=true&withQuestion=true&withTaggedUsers=true';
    if (assessmentId) query += `&assessmentId=${assessmentId}`;
    if (documentDraftId) query += `&documentDraftId=${documentDraftId}`;
    const res = await this.instance.get<{ data: Thread[] }>(query, {
      withCredentials: true,
    });
    return res.data;
  };

  public updateDocument = async (
    document: DocumentState,
  ): Promise<DocumentFromResponse> => {
    const res = await this.instance.put<{ data: DocumentFromResponse }>(
      `/documents/${document.id}`,
      { ...document, file: JSON.stringify(document.file) },
      { withCredentials: true },
    );
    return res.data;
  };

  public saveComment = async (
    commentData: Partial<Comment>,
  ): Promise<Comment> => {
    const res = await this.instance.post<{ data: Comment }>(
      '/comments',
      commentData,
      { withCredentials: true },
    );
    return res.data;
  };

  public deleteComment = async (commentId: number) =>
    this.instance.delete(`/comments/${commentId}`, { withCredentials: true });

  public getLatestNotifications = async (
    userId: number,
  ): Promise<NotificationFromResponse[]> => {
    const res = await this.instance.get<{ data: NotificationFromResponse[] }>(
      `/notifications/latest?toId=${userId}`,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public getNotificationsByUserId = async (
    request: PaginatedNotificationRequest,
  ): Promise<PaginatedNotifications> => {
    const res = await this.instance.get<{ data: PaginatedNotifications }>(
      `/users/${request.userId}/notifications?archived=${request.archived}&page=${request.page}&pageSize=${request.pageSize}`,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public updatesNotificationListingAsRead = async (
    ids: number[],
  ): Promise<NotificationFromResponse[]> => {
    const res = await this.instance.put<{ data: NotificationFromResponse[] }>(
      `/notifications/read`,
      { ids },
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public updateNotification = async (
    notification: NotificationUpdateParams,
  ): Promise<NotificationFromResponse> => {
    const res = await this.instance.put<{ data: NotificationFromResponse }>(
      `/notifications/${notification.id}`,
      notification,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public loadTemplate = async (templateId: number): Promise<TemplateState> => {
    const res = await this.instance.get<{ data: TemplateState }>(
      `/templates/${templateId}`,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public updateTemplate = async (
    templateId: number,
    templateData: any,
  ): Promise<TemplateState> => {
    const res = await this.instance.put<{ data: TemplateState }>(
      `/templates/${templateId}`,
      templateData,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public saveTemplate = async (
    templateData: Partial<TemplateState>,
  ): Promise<TemplateState> => {
    const res = await this.instance.post<{ data: TemplateState }>(
      '/templates',
      templateData,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public getTemplateList = async (
    assessmentTypeId?: number,
  ): Promise<TemplateState[]> => {
    const query = assessmentTypeId
      ? `?assessmentTypeId=${assessmentTypeId}&withAssessmentType=true&withParentTemplateId=true`
      : '?withParentTemplateId=true&withAssessmentType=true';
    const res = await this.instance.get<{ data: TemplateState[] }>(
      `/templates${query}`,
      { withCredentials: true },
    );
    return res.data;
  };

  public deleteTemplate = async (templateId: number) =>
    this.instance.delete(`/templates/${templateId}`, { withCredentials: true });

  public cloneTemplate = async (
    templateData: TemplateInput,
    templateId: number,
    onlyDuplicate: boolean,
  ): Promise<TemplateState> => {
    let url = `/templates/${templateId}/clone`;
    if (onlyDuplicate) url += '?onlyDuplicate=true';
    const res = await this.instance.post<{ data: TemplateState }>(
      url,
      templateData,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public getFormQuestionsByAssessmentType = async (
    assessmentTypeId: number,
  ): Promise<FormQuestionsState[]> => {
    const res = await this.instance.get<{ data: FormQuestionsState[] }>(
      `/forms?&withQuestions=true&assessmentTypeId=${assessmentTypeId}`,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public saveVariable = async (
    variableData: VariableInput,
  ): Promise<VariableState> => {
    const res = await this.instance.post<{ data: VariableState }>(
      '/variables',
      variableData,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public updateVariable = async (
    variableId: number,
    variableData: VariableInput,
  ): Promise<VariableState> => {
    const res = await this.instance.put<{ data: VariableState }>(
      `/variables/${variableId}`,
      variableData,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public deleteVariable = async (variableId: number): Promise<boolean> => {
    const res = await this.instance.delete<{ success: boolean }>(
      `/variables/${variableId}`,
      {
        withCredentials: true,
      },
    );
    return res.success;
  };

  public saveDocumentDraft = async (
    documentDraftInput: DocumentDraftInput,
  ): Promise<DocumentDraftState> => {
    const res = await this.instance.post<{ data: DocumentDraftState }>(
      '/document_drafts',
      documentDraftInput,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public getDocumentDraftById = async (
    documentDraftId: number | string,
    withTemplate: boolean,
  ): Promise<DocumentDraftFromResponse> => {
    const url = `/document_drafts/${documentDraftId}`;
    const res = await this.instance.get<{ data: DocumentDraftFromResponse }>(
      withTemplate ? `${url}?withTemplate=true` : url,
      { withCredentials: true },
    );
    return res.data;
  };

  public updateDocumentDraft = async (
    documentDraftId: string | number,
    documentDraft: DocumentDraftInput,
  ): Promise<DocumentDraftFromResponse> => {
    const res = await this.instance.put<{ data: DocumentDraftFromResponse }>(
      `/document_drafts/${documentDraftId}`,
      documentDraft,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public resetPassword = async (username: string): Promise<string> => {
    return this.instance.post<string>(
      `/reset_password?email=${encodeURIComponent(username)}`,
    );
  };

  public userUpdate = async (
    userInput: UserUpdateInput,
    authToken?: string,
  ) => {
    const { id } = userInput;
    const auth = authToken
      ? { headers: { Authorization: authToken } }
      : { withCredentials: true };
    const res = await this.instance.put<{ data: User }>(
      `/users/${id}`,
      userInput,
      auth,
    );
    return res.data;
  };

  public updateCompany = async (
    companyId: number,
    company: Partial<CompanyState>,
  ): Promise<CompanyFromResponse> => {
    const res = await this.instance.put<{ data: CompanyFromResponse }>(
      `/companies/${companyId}`,
      company,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public archiveCompany = async (
    companyId: number,
    company: Partial<CompanyState>,
  ): Promise<CompanyFromResponse> => {
    const res = await this.instance.put<{ data: CompanyFromResponse }>(
      `/companies/${companyId}/archive`,
      company,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public saveAssessment = async (
    assessmentInput: AssessmentInput,
  ): Promise<AssessmentFromResponse> => {
    const res = await this.instance.post<{ data: AssessmentFromResponse }>(
      '/assessments',
      assessmentInput,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public saveCompany = async (
    companyInput: CompanyInput,
  ): Promise<CompanyFromResponse> => {
    const res = await this.instance.post<{ data: CompanyFromResponse }>(
      '/companies',
      companyInput,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public saveCompanyUser = async (
    user: UserInput,
  ): Promise<UserFromResponse> => {
    const res = await this.instance.post<{ data: UserFromResponse }>(
      '/signup',
      user,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public saveGlobalUser = async (
    user: UserInput,
  ): Promise<UserFromResponse> => {
    const res = await this.instance.post<{ data: UserFromResponse }>(
      '/signup/admin',
      user,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public deleteUser = async (userId: number): Promise<void> => {
    await this.instance.delete(`/users/${userId}`, { withCredentials: true });
  };

  public getDocumentDraftList = async (
    documentId?: number,
  ): Promise<DocumentDraftFromResponse[]> => {
    let url = '/document_drafts';
    if (documentId) {
      url += `?documentId=${documentId}`;
    }
    const res = await this.instance.get<{ data: DocumentDraftFromResponse[] }>(
      url,
      { withCredentials: true },
    );
    return res.data;
  };

  public get2FACode = async (): Promise<string> => {
    try {
      const response = await this.instance.post(
        '/2fa/generate',
        {},
        {
          withCredentials: true,
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'image/png',
          },
        },
      );

      if (response) {
        const uint8Array = new Uint8Array(response);
        const base64Image = btoa(String.fromCharCode(...uint8Array));
        return base64Image;
      } else {
        console.error('No data received in 2FA code generation response');
        return '';
      }
    } catch (error) {
      console.error('Error generating 2FA code:', error);
      return '';
    }
  };

  public send2faSMS = async (
    dialCode: string,
    phoneNumber: string,
  ): Promise<string> => {
    const sendedCode = await this.instance.post(
      '/2fa/sms',
      { dialCode, phoneNumber },
      { withCredentials: true },
    );

    return sendedCode;
  };

  public turnOn2FA = async (
    code: string,
    authMethod: AuthMethod,
    dialCode?: string,
    phoneNumber?: string,
  ): Promise<{ isTwoFactorAuthenticationEnabled: boolean; user: User }> => {
    const res = await this.instance.post<{
      data: { isTwoFactorAuthenticationEnabled: boolean; user: User };
    }>(
      '/2fa/turn-on',
      {
        twoFactorAuthenticationCode: code,
        authMethod,
        dialCode,
        phoneNumber,
      },
      {
        withCredentials: true,
      },
    );

    return res.data;
  };

  public auth2FA = async (
    code: string,
    authMethod: AuthMethod,
  ): Promise<User> => {
    const res = await this.instance.post<{ data: User }>(
      '/2fa/authenticate',
      {
        twoFactorAuthenticationCode: code,
        authMethod,
      },
      {
        withCredentials: true,
      },
    );

    return res.data;
  };

  public saveMultipleUsers = async (
    users: UserInput[],
  ): Promise<MultipleUsersCreationResponse> => {
    return this.instance.post<MultipleUsersCreationResponse>(
      '/signupMany',
      users,
      {
        withCredentials: true,
      },
    );
  };

  public getConstantList = async (): Promise<ConstantState[]> => {
    const res = await this.instance.get<{ data: ConstantState[] }>(
      `/constants`,
      {
        withCredentials: true,
      },
    );
    return res.data;
  };

  public clear2FAFromUser = async (userId: number): Promise<string> => {
    const res = await this.instance.delete<{ data: { message: string } }>(
      `/2fa/user/${userId}`,
      {
        withCredentials: true,
      },
    );

    return res.data?.message;
  };

  public updateThread = async (thread: Thread): Promise<Thread> => {
    const res = await this.instance.put<{ data: Thread }>(
      `/threads/${thread.id}`,
      thread,
      {
        withCredentials: true,
      },
    );

    return res.data;
  };

  public getUsers = async (
    page: number,
    pageSize: number,
  ): Promise<UserFromResponse[]> => {
    const res = await this.instance.get<{ data: UserFromResponse[] }>(
      `/users?&page=${page}&pageSize=${pageSize}`,
      {
        withCredentials: true,
      },
    );

    return res.data;
  };

  public getTrainees = async (companyId: number): Promise<Trainee[]> => {
    const res = await this.instance.get<{ data: Trainee[] }>(
      `/users/trainees/${companyId}`,
      {
        withCredentials: true,
      },
    );

    return res.data;
  };

  public createCategory = async (
    name: string,
  ): Promise<CategoryFromResponse> => {
    const res = await this.instance.post<{ data: CategoryFromResponse }>(
      `/category`,
      {
        name,
      },
      {
        withCredentials: true,
      },
    );

    return res.data;
  };

  public loadCategories = async (): Promise<CategoryFromResponse[]> => {
    const res = await this.instance.get<{ data: CategoryFromResponse[] }>(
      `/category`,
      {
        withCredentials: true,
      },
    );

    return res.data;
  };

  public reorderCategories = async (
    categories: CategoryInput[],
  ): Promise<void> => {
    await this.instance.post(
      `/category/reorder`,
      {
        data: categories,
      },
      {
        withCredentials: true,
      },
    );
  };

  public reorderForms = async (forms: Partial<FormInput>[]): Promise<void> => {
    await this.instance.post(
      `/forms/reorder`,
      {
        data: forms,
      },
      {
        withCredentials: true,
      },
    );
  };

  public deleteCategory = async (categoryId: number): Promise<void> => {
    await this.instance.delete(`/category/${categoryId}`, {
      withCredentials: true,
    });
  };

  public updateCategory = async (
    categoryInput: Partial<CategoryInput>,
  ): Promise<CategoryFromResponse> => {
    return this.instance.put<CategoryFromResponse>(
      `/category/${categoryInput.id}`,
      {
        name: categoryInput.name,
      },
      {
        withCredentials: true,
      },
    );
  };

  public loadAssessmentTypeLabels = async (
    assessmentTypeId: number,
  ): Promise<AssessmentTypesLabelFromResponse[]> => {
    const res = await this.instance.get(
      `/assessment_types/${assessmentTypeId}/labels`,
      {
        withCredentials: true,
      },
    );

    return res.data as AssessmentTypesLabelFromResponse[];
  };

  public loadAssessmentTypeDelegates = async (
    assessmentTypeId: number,
  ): Promise<AssessmentTypesDelegateFromResponse[]> => {
    const res = await this.instance.get(
      `/assessment_types/${assessmentTypeId}/delegates`,
      {
        withCredentials: true,
      },
    );

    return res.data as AssessmentTypesDelegateFromResponse[];
  };

  public createAssignee = async (
    interviewId: number,
    assigneeId: number,
    questionId: number,
  ): Promise<AssigneeFromResponse> => {
    const res = await this.instance.post(
      `/interviews/${interviewId}/assignee`,
      {
        interviewId,
        assigneeId,
        questionId,
      },
      {
        withCredentials: true,
      },
    );

    return res.data as AssigneeFromResponse;
  };

  public deleteAssignee = async (
    interviewId: number,
    assigneeId: number,
  ): Promise<void> => {
    await this.instance.delete(
      `/interviews/${interviewId}/assignee/${assigneeId}`,
      {
        withCredentials: true,
      },
    );
  };

  public getAssignees = async (
    interviewId: number,
  ): Promise<AssigneeFromResponse[]> => {
    const res = await this.instance.get(`/interviews/${interviewId}/assignee`, {
      withCredentials: true,
    });

    return res.data as AssigneeFromResponse[];
  };

  public assignOwner = async (
    interviewId: number,
    ownerId: number,
  ): Promise<InterviewFromResponse> => {
    const res = await this.instance.put(
      `/interviews/${interviewId}/owner`,
      {
        ownerId,
      },
      {
        withCredentials: true,
      },
    );

    return res.data as InterviewFromResponse;
  };

  public removeOwnerFromInterview = async (
    interviewId: number,
    ownerId: number,
  ): Promise<InterviewFromResponse> => {
    const res = await this.instance.put(
      `/interviews/${interviewId}/owner/${ownerId}`,
      {},
      {
        withCredentials: true,
      },
    );

    return res.data as InterviewFromResponse;
  };

  public updateCollapsedSider = async (
    collapseSideBar: boolean,
  ): Promise<void> => {
    await this.instance.put(
      `/users/me/preferences/sidebar`,
      {
        collapseSideBar,
      },
      {
        withCredentials: true,
      },
    );
  };

  public getTenantSettings = async (): Promise<any> => {
    const res = await this.instance.get(`/tenant/settings`);

    return res.data;
  };

  public getAssessmentReportUrl = async (
    documentId: number,
  ): Promise<{ assessmentReportPdfUrl: string }> => {
    const res = await this.instance.get(`/documents/${documentId}/report`, {
      withCredentials: true,
    });
    return res.data;
  };

  public getRiskSummaryReportUrl = async (
    documentId: number,
  ): Promise<ArrayBuffer> => {
    const res = await this.instance.get(`/documents/${documentId}/risks`, {
      withCredentials: true,
      responseType: 'arraybuffer',
    });

    return res;
  };

  public loadAssessmentRiskAnalysis = async (
    assessmentId: number,
  ): Promise<AssessmentRiskAnalysisFromResponse> => {
    const res = await this.instance.get(
      `/assessments/${assessmentId}/compute/risks`,
      {
        withCredentials: true,
      },
    );

    return res.data;
  };

  public getUserOnboarding = async (
    userId: number,
  ): Promise<UserOnboardingFromResponse> => {
    const res = await this.instance.get(`/users/${userId}/onboarding`, {
      withCredentials: true,
    });

    return res.data;
  };

  public completeUserOnboarding = async (
    id: number,
  ): Promise<UserFromResponse> => {
    const res = await this.instance.put<{ data: UserFromResponse }>(
      `/users/${id}/onboarding/disable`,
      {},
      {
        withCredentials: true,
      },
    );

    return res.data;
  };
}

export const api = new ThApi();
