import React, { FC } from 'react';
import { Pagination as PaginationAntD } from 'antd';
import styled, { css } from 'styled-components';
import { Button } from 'src/components/Button';

interface PaginationProps {
  current?: number;
  items: number;
  onPageChange: (size: number) => void;
  onPageSizeChange: (size: number) => void;
  pageSize?: number;
  sizes?: number[];
  isNewStyle?: boolean;
}

const MainContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  width: 100%;
`;

const BaseContainerStyle = css`
  display: flex;
  align-items: center;

  > span {
    font-size: 14px;
    letter-spacing: 0.05em;
    margin-right: 12px;
  }
`;

const ButtonStyles = `
  background: rgb(243, 243, 243);
  color: rgb(90, 90, 90);
  border: grey;
  padding: 10px 10px;

  &.selected {
    background: rgb(60, 149, 179);
    color: rgb(255, 255, 255);
  }

  &:hover {
    background: rgb(200, 200, 200);
    color: rgb(90, 90, 90);
  }

  &:disabled {
    background: rgb(243, 243, 243);
    color: rgb(90, 90, 90);
    opacity: 0.5;
  }
`;

const PaginationContainer = styled.div<{ isNewStyle: boolean }>`
  ${BaseContainerStyle};

  ${(props) =>
    props.isNewStyle &&
    `
      .jump-last,
      .jump-first {
        margin-top: 8px;
      }

      .jump-first {
        margin-right: 7px;
      }

      .jump-last {
        margin-left: 7px;
      }

      button {
        ${ButtonStyles}
      }
  `}
`;

const ItemsPerSheetContainer = styled.div<{ isNewStyle: boolean }>`
  ${BaseContainerStyle};

  > span {
    margin-right: 9px;
  }

  .btn {
    margin: 3px;
  }

  .ant-pagination-item {
    border: none;
  }

  ${(props) =>
    props.isNewStyle &&
    `
    button {
      ${ButtonStyles}
    }
  `}
`;

const PaginationStyled = styled(PaginationAntD)<{ isNewStyle: boolean }>`
  display: inline-flex;
  align-items: center;

  .ant-pagination-item {
    border: none;
  }
`;

const itemRender = (
  page: number,
  type: string,
  originalElement: any,
  current: number,
): React.ReactNode => {
  if (type === 'prev') return <Button variant={'radio'} title={'<'} />;
  if (type === 'next') return <Button variant={'radio'} title={'>'} />;
  if (type === 'jump-prev' || type === 'jump-next')
    return <Button variant={'radio'} title={'...'} />;
  return (
    <Button
      altered={current === page}
      variant={'radio'}
      title={page.toString()}
    />
  );
};

export const Pagination: FC<PaginationProps> = ({
  current = 1,
  items,
  onPageChange,
  onPageSizeChange,
  pageSize = 10,
  sizes = [10, 50, 100],
  isNewStyle = false,
}) => {
  return (
    <MainContainer>
      <ItemsPerSheetContainer isNewStyle={isNewStyle}>
        <span>Items Per Sheet:</span>
        {sizes.map((size) => (
          <Button
            altered={pageSize === size}
            className="btn"
            onClick={() => onPageSizeChange(size)}
            variant={'radio'}
            title={size === items ? 'All' : size.toString()}
            key={size}
          />
        ))}
      </ItemsPerSheetContainer>
      <PaginationContainer isNewStyle={isNewStyle}>
        <span>Pages:</span>
        {isNewStyle && (
          <Button
            className={'jump-first'}
            variant={'radio'}
            title={'<<'}
            onClick={() => onPageChange(1)}
            disabled={current === 1}
          />
        )}
        <PaginationStyled
          isNewStyle={isNewStyle}
          current={current}
          itemRender={(page, type, originalElement) =>
            itemRender(page, type, originalElement, current)
          }
          onChange={(page) => onPageChange(page)}
          pageSize={pageSize}
          showSizeChanger={false}
          total={items}
        />
        {isNewStyle && (
          <Button
            className={'jump-last'}
            variant={'radio'}
            title={'>>'}
            onClick={() => onPageChange(Math.ceil(items / pageSize))}
            disabled={current === Math.ceil(items / pageSize)}
          />
        )}
      </PaginationContainer>
    </MainContainer>
  );
};
