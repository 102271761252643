import { combineReducers } from 'redux';

import { AuthMethod } from 'src/types/common';
import {
  User,
  UserRole,
  UserActionTypes,
  Loader,
  SelectedUserState,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGIN_CHECK,
  LOGIN_CHECK_COMPLETED,
  NOTIFICATIONS_LOAD_SUCCESS,
  NOTIFICATIONS_READ_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  USER_UPDATE_ERROR,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_SAVE_REQUEST,
  USER_SAVE_SUCCESS,
  USER_SAVE_ERROR,
  GET_2FA_CODE_SUCCESS,
  AUTH_2FA_REQUEST,
  AUTH_2FA_ERROR,
  AUTH_2FA_SUCCESS,
  TURN_ON_2FA_REQUEST,
  TURN_ON_2FA_ERROR,
  TURN_ON_2FA_SUCCESS,
  MULTIPLE_USERS_SAVE_SUCCESS,
  MULTIPLE_USERS_SAVE_REQUEST,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_ERROR,
  USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_SUCCESS,
  IMPERSONATE_REQUEST,
  IMPERSONATE_SUCCESS,
  IMPERSONATE_ERROR,
  USER_COMPLETE_ONBOARDING_SUCCESS,
  USER_COMPLETE_ONBOARDING_REQUEST,
  USER_COMPLETE_ONBOARDING_ERROR,
  GET_USER_ONBOARDING_SUCCESS,
  GET_USER_ONBOARDING_ERROR,
  GET_USER_ONBOARDING_REQUEST,
} from 'src/store/types';

export const loaderInitialState: Loader = {
  loading: false,
  success: false,
  error: '',
  completed: false,
};

export const userInitialState: User = {
  id: 0,
  name: '',
  email: '',
  role: UserRole.anonymous,
  isActive: true,
  hasPendingNotifications: false,
  avatarImg: '',
  authMethod: AuthMethod.APP,
  isTwoFactorAuthenticationEnabled: false,
  isSecondFactorAuthenticated: false,
  preference: {
    collapseSideBar: false,
  },
  attributes: {},
  isOnboardingRequired: false,
};

export const userReducer = (
  state: User = userInitialState,
  action: UserActionTypes,
): User => {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case IMPERSONATE_SUCCESS:
      return {
        ...state,
        ...action.user,
      };
    case USER_COMPLETE_ONBOARDING_SUCCESS: {
      return {
        ...state,
        isOnboardingRequired: action.user.isOnboardingRequired,
      };
    }
    case GET_USER_ONBOARDING_SUCCESS: {
      return {
        ...userInitialState,
        id: action.user.id,
        isOnboardingRequired: action.user.isOnboardingRequired,
        role: action.user.role,
      };
    }
    case LOGOUT_SUCCESS:
      return userInitialState;
    case NOTIFICATIONS_LOAD_SUCCESS: {
      const hasPendingNotifications = !!action.notifications.records.find(
        (n) => n.pending,
      );
      return { ...state, hasPendingNotifications };
    }
    case NOTIFICATIONS_READ_SUCCESS: {
      return { ...state, hasPendingNotifications: false };
    }
    case GET_2FA_CODE_SUCCESS: {
      return { ...state, twoFactorQR: action.code };
    }
    case TURN_ON_2FA_SUCCESS: {
      return {
        ...userInitialState,
        ...action.user,
        isTwoFactorAuthenticationEnabled: true,
        isSecondFactorAuthenticated: true,
      };
    }
    case AUTH_2FA_SUCCESS: {
      return { ...state, ...action.user };
    }
    case USER_UPDATE_COLLAPSED_SIDER_PREFERENCE_SUCCESS: {
      return {
        ...state,
        preference: {
          collapseSideBar: action.collapsedSider,
        },
      };
    }
    default:
      return state;
  }
};

export const loaderReducer = (
  state: Loader = loaderInitialState,
  action: UserActionTypes,
): Loader => {
  switch (action.type) {
    case LOGIN_CHECK:
    case LOGIN_REQUEST:
    case IMPERSONATE_REQUEST:
    case LOGOUT_REQUEST:
    case USER_UPDATE_REQUEST:
    case USER_DELETE_REQUEST:
    case RESET_PASSWORD_REQUEST:
    case TURN_ON_2FA_REQUEST:
    case AUTH_2FA_REQUEST:
    case MULTIPLE_USERS_SAVE_REQUEST:
    case USER_SAVE_REQUEST:
    case GET_USER_ONBOARDING_REQUEST:
    case USER_COMPLETE_ONBOARDING_REQUEST:
      return { ...loaderInitialState, loading: true };
    case LOGIN_SUCCESS:
    case IMPERSONATE_SUCCESS:
    case USER_UPDATE_SUCCESS:
    case USER_DELETE_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
    case LOGOUT_SUCCESS:
    case TURN_ON_2FA_SUCCESS:
    case AUTH_2FA_SUCCESS:
    case MULTIPLE_USERS_SAVE_SUCCESS:
    case USER_SAVE_SUCCESS:
    case GET_USER_ONBOARDING_SUCCESS:
    case USER_COMPLETE_ONBOARDING_SUCCESS:
      return { ...loaderInitialState, success: true, completed: true };
    case LOGIN_ERROR:
    case IMPERSONATE_ERROR:
    case LOGOUT_ERROR:
    case USER_UPDATE_ERROR:
    case USER_DELETE_ERROR:
    case RESET_PASSWORD_ERROR:
    case TURN_ON_2FA_ERROR:
    case AUTH_2FA_ERROR:
    case USER_SAVE_ERROR:
    case USER_COMPLETE_ONBOARDING_ERROR:
      return {
        ...loaderInitialState,
        error: action.message.toString(),
        completed: true,
      };
    case LOGIN_CHECK_COMPLETED:
      return { ...loaderInitialState, completed: true };
    default:
      return state;
  }
};

export const combinedUserReducer = combineReducers<SelectedUserState>({
  user: userReducer,
  loader: loaderReducer,
});
