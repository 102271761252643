import {
  NotificationUpdateErrorAction,
  NotificationUpdateRequestAction,
  NotificationUpdateSuccessAction,
  NotificationListingLoadErrorAction,
  NotificationListingLoadRequestAction,
  NotificationListingLoadSuccessAction,
  NotificationsReadErrorAction,
  NotificationsReadRequestAction,
  NotificationsReadSuccessAction,
  NotificationsPollingStartAction,
  NOTIFICATION_UPDATE_ERROR,
  NOTIFICATION_UPDATE_REQUEST,
  NOTIFICATION_UPDATE_SUCCESS,
  NOTIFICATIONS_LOAD_ERROR,
  NOTIFICATIONS_LOAD_REQUEST,
  NOTIFICATIONS_LOAD_SUCCESS,
  NOTIFICATIONS_READ_ERROR,
  NOTIFICATIONS_READ_REQUEST,
  NOTIFICATIONS_READ_SUCCESS,
  NOTIFICATIONS_POLLING_START,
  NotificationFromResponse,
  NotificationUpdateParams,
  PaginatedNotifications,
  PaginatedNotificationRequest,
} from '../types';
import { showErrorMessage } from './error';

export const notificationsPollingStart = (
  userId: number,
): NotificationsPollingStartAction => ({
  type: NOTIFICATIONS_POLLING_START,
  userId,
});

export const notificationListingLoadRequest = (
  request: PaginatedNotificationRequest,
): NotificationListingLoadRequestAction => ({
  type: NOTIFICATIONS_LOAD_REQUEST,
  request,
});

export const notificationListingLoadSuccess = (
  notifications: PaginatedNotifications,
): NotificationListingLoadSuccessAction => ({
  type: NOTIFICATIONS_LOAD_SUCCESS,
  notifications,
});

export const notificationListingLoadError = (
  errorMessage: string,
  originalError: Error,
): NotificationListingLoadErrorAction =>
  showErrorMessage(NOTIFICATIONS_LOAD_ERROR, errorMessage, originalError);

export const notificationsMarkAsReadRequest = (
  ids: number[],
): NotificationsReadRequestAction => ({
  type: NOTIFICATIONS_READ_REQUEST,
  ids,
});

export const notificationsMarkAsReadSuccess =
  (): NotificationsReadSuccessAction => ({
    type: NOTIFICATIONS_READ_SUCCESS,
  });

export const notificationsMarkAsReadError = (
  errorMessage: string,
  originalError: Error,
): NotificationsReadErrorAction =>
  showErrorMessage(NOTIFICATIONS_READ_ERROR, errorMessage, originalError);

export const notificationUpdateRequestAction = (
  notification: NotificationUpdateParams,
): NotificationUpdateRequestAction => ({
  type: NOTIFICATION_UPDATE_REQUEST,
  notification,
});

export const notificationUpdateSuccessAction = (
  notification: NotificationFromResponse,
): NotificationUpdateSuccessAction => ({
  type: NOTIFICATION_UPDATE_SUCCESS,
  notification,
});

export const notificationUpdateErrorAction = (
  errorMessage: string,
  originalError: Error,
): NotificationUpdateErrorAction =>
  showErrorMessage(NOTIFICATION_UPDATE_ERROR, errorMessage, originalError);
