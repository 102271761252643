import { Loader } from './loader';
import { CompanyFromResponse, CompanyState, TypeOfTraining } from './company';
import { ShowErrorAction } from './error';

export const COMPANIES_LOAD_REQUEST = 'COMPANIES_LOAD_REQUEST';
export const COMPANIES_LOAD_SUCCESS = 'COMPANIES_LOAD_SUCCESS';
export const COMPANIES_LOAD_ERROR = 'COMPANIES_LOAD_ERROR';
export const COMPANY_ARCHIVE_REQUEST = 'COMPANY_ARCHIVE_REQUEST';
export const COMPANY_ARCHIVE_SUCCESS = 'COMPANY_ARCHIVE_SUCCESS';
export const COMPANY_ARCHIVE_ERROR = 'COMPANY_ARCHIVE_ERROR';
export const ADD_COMPANY_TO_LIST = 'ADD_COMPANY_TO_LIST';

export interface CompanyListingIndexedItem {
  [key: string]: CompanyState;
}

export interface CompanyListingState {
  companies: CompanyListingIndexedItem;
  page: number;
  pageSize: number;
  totalItems: number;
  loader: Loader;
}

export interface CompaniesLoadRequestAction {
  type: typeof COMPANIES_LOAD_REQUEST;
  typeOfTrainings: null | TypeOfTraining[];
  active: boolean;
  page: number;
  pageSize: number;
  name: string;
}

export interface CompaniesLoadSuccessAction {
  type: typeof COMPANIES_LOAD_SUCCESS;
  companies: CompanyFromResponse[];
  page: number;
  pageSize: number;
  totalItems: number;
}

export type CompaniesLoadErrorAction = ShowErrorAction<
  typeof COMPANIES_LOAD_ERROR
>;

export interface ArchiveCompanyRequestAction {
  type: typeof COMPANY_ARCHIVE_REQUEST;
  companyId: number;
}

export interface ArchiveCompanySuccessAction {
  type: typeof COMPANY_ARCHIVE_SUCCESS;
  archivedCompany: CompanyFromResponse;
}

export type ArchiveCompanyErrorAction = ShowErrorAction<
  typeof COMPANY_ARCHIVE_ERROR
>;

export interface AddCompanyToListAction {
  type: typeof ADD_COMPANY_TO_LIST;
  company: CompanyFromResponse;
}

export type CompanyListingActionTypes =
  | CompaniesLoadRequestAction
  | CompaniesLoadSuccessAction
  | CompaniesLoadErrorAction
  | ArchiveCompanyRequestAction
  | ArchiveCompanySuccessAction
  | ArchiveCompanyErrorAction
  | AddCompanyToListAction;
