import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from 'src/theme/icons';

interface ConfirmDialogParams {
  text: string;
  title?: string;
  okText?: string;
  cancelText?: string;
  onOk?: (params?: any) => void;
  onCancel?: () => void;
}

export const confirmDialog: (props: ConfirmDialogParams) => void = ({
  text,
  okText,
  cancelText,
  title,
  onOk,
  onCancel,
}) =>
  Modal.confirm({
    title: title || 'Please confirm',
    icon: <ExclamationCircleOutlined />,
    content: text,
    okText: okText || 'Continue',
    cancelText: cancelText || 'Cancel',
    onOk,
    onCancel,
  });
