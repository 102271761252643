import React, { FC } from 'react';
import { Button as ButtonAntd } from 'antd';
import { ButtonProps as ButtonAntdProps } from 'antd/lib/button';
import styled from 'styled-components';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { remConvert } from 'src/theme/utils';

const StyledButton = styled(ButtonAntd)<StyledButtonProps>`
  background: linear-gradient(
    84.58deg,
    ${({ theme }): string => theme.colors.astral.main} 12.28%,
    ${({ theme }): string => theme.colors.astral.main} 79.73%
  );
  box-shadow: ${remConvert(0)} ${remConvert(3)} ${remConvert(10)}
    rgba(0, 0, 0, 0.25);
  border-radius: ${remConvert(4)};
  border: ${remConvert(0)};
  font-family: ${({ theme }): string => theme.fontFamilies.primary};
  font-style: ${({ theme }): string => theme.fontStyles.normal};
  font-weight: ${({ theme }): string => String(theme.fontWeights.semiBold)};
  font-size: ${({ theme }): string => String(theme.fontSizes.body)};
  line-height: ${({ theme }): string => remConvert(theme.lineHeights[22])};

  .ant-btn-primary {
    border-color: ${({ theme }): string => theme.colors.red.main} !important;
  }

  &:focus,
  &:hover {
    background: linear-gradient(
      84.58deg,
      ${({ theme }): string => theme.colors.astral.main} 12.28%,
      ${({ theme }): string => theme.colors.bostonBlue.main} 79.73%
    );
    box-shadow: ${remConvert(0)} ${remConvert(3)} ${remConvert(10)}
      rgba(0, 0, 0, 0.25);
    font-family: ${({ theme }): string => theme.fontFamilies.primary};
    font-size: ${({ theme }): string => String(theme.fontSizes.body)};
    line-height: ${({ theme }): string => remConvert(theme.lineHeights[22])};
  }

  &:disabled,
  &:disabled:hover {
    background: linear-gradient(
      84.58deg,
      ${({ theme }): string => theme.colors.nepal.main} 12.28%,
      ${({ theme }): string => theme.colors.nepal.main} 79.73%
    );
    box-shadow: ${remConvert(0)} ${remConvert(2)} ${remConvert(4)}
      rgba(0, 0, 0, 0.2);
    border-color: ${remConvert(0)} ${remConvert(2)} ${remConvert(4)}
      rgba(0, 0, 0, 0.2);
    color: ${({ theme }): string => theme.colors.twilightBlue.main};
  }

  &:focus,
  &:disabled,
  &:hover,
  &:disabled:hover {
    border-radius: ${remConvert(4)};
  }
`;

interface ButtonProps extends ButtonAntdProps {
  className?: string;
  disabled?: boolean;
  altered?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'radio' | 'danger';
  title: string;
  size?: SizeType;
}

interface StyledButtonProps extends ButtonAntdProps {
  $altered?: boolean;
  children: string;
  variant: 'primary' | 'secondary' | 'tertiary' | 'radio' | 'danger';
}

export const Button: FC<ButtonProps> = ({
  className,
  disabled,
  onClick,
  altered = false,
  title,
  variant = 'primary',
  loading,
  size,
}) => (
  <StyledButton
    $altered={altered}
    disabled={disabled}
    className={`${className} ${altered ? 'selected' : ''}`}
    type="primary"
    variant={variant}
    onClick={onClick}
    loading={loading}
    size={size || 'large'}
  >
    {title}
  </StyledButton>
);
