import {
  ASSESSMENT_LOAD_ERROR,
  ASSESSMENT_LOAD_SUGGESTIONS_ERROR,
  ASSESSMENT_SAVE_ERROR,
  ASSESSMENT_TYPES_LOAD_ALL_ERROR,
  ASSESSMENT_TYPES_LOAD_LABEL_FAILED,
  ASSESSMENT_UPDATE_ERROR,
  ASSESSMENT_UPDATE_STATUS_ERROR,
  AUTH_2FA_ERROR,
  CATEGORY_LOAD_REQUEST_FAILED,
  CLEAR_2FA_ERROR,
  COMMENT_DELETE_ERROR,
  COMMENT_SAVE_ERROR,
  COMPANIES_LOAD_ERROR,
  COMPANY_ARCHIVE_ERROR,
  COMPANY_LOAD_ERROR,
  COMPANY_SAVE_ERROR,
  COMPANY_UPDATE_ERROR,
  CONSTANTS_LOAD_ERROR,
  DOCUMENT_APPROVE_ERROR,
  DOCUMENT_CLOSE_ERROR,
  DOCUMENT_DRAFT_LOAD_ERROR,
  DOCUMENT_DRAFT_SAVE_ERROR,
  DOCUMENT_DRAFT_UPDATE_ERROR,
  DOCUMENT_LOAD_ERROR,
  DOCUMENT_PP_SAVE_ERROR,
  DOCUMENT_SAVE_ERROR,
  DOCUMENT_SET_IN_CLIENT_REVIEW_ERROR,
  DOCUMENTS_BY_ASSESSMENT_LOAD_ERROR,
  DOCUMENTS_DRAFTS_LOAD_ERROR,
  ErrorActionTypes,
  ErrorSeverity,
  ErrorState,
  FORM_CLONE_ERROR,
  FORM_DELETE_ERROR,
  FORM_LOAD_QUESTIONS_ERROR,
  FORM_SAVE_ERROR,
  FORM_SET_STATUS_ERROR,
  FORM_UPDATE_ERROR,
  FORMS_LOAD_ERROR,
  FORMS_REORDER_FAILED,
  GET_2FA_CODE_ERROR,
  GET_USERS_ERROR,
  INTERVIEW_LOAD_ANSWERS_ERROR,
  INTERVIEW_SAVE_ERROR,
  INTERVIEW_UPDATE_ERROR,
  LOGIN_ERROR,
  LOGOUT_ERROR,
  MULTIPLE_USERS_SAVE_ERROR,
  NOTIFICATIONS_LOAD_ERROR,
  NOTIFICATIONS_READ_ERROR,
  QUESTION_TYPES_LOAD_ERROR,
  QUESTIONS_LOAD_ERROR,
  RESET_PASSWORD_ERROR,
  SHOW_ERROR,
  TEMPLATE_CLONE_ERROR,
  TEMPLATE_DELETE_ERROR,
  TEMPLATE_LOAD_ERROR,
  TEMPLATE_SAVE_ERROR,
  TEMPLATE_UPDATE_ERROR,
  TEMPLATES_LOAD_ERROR,
  THREAD_LIST_LOAD_ERROR,
  THREAD_UPDATE_ERROR,
  TURN_ON_2FA_ERROR,
  USER_DELETE_ERROR,
  USER_SAVE_ERROR,
  USER_UPDATE_ERROR,
  VARIABLE_DELETE_ERROR,
  VARIABLE_SAVE_ERROR,
  VARIABLE_UPDATE_ERROR,
} from 'src/store/types';

const errorInitialState = {
  message: '',
  severity: ErrorSeverity['ERROR'],
  originalError: new Error(),
};

const isUserError = (type: string) =>
  type === USER_UPDATE_ERROR ||
  type === USER_SAVE_ERROR ||
  type === GET_USERS_ERROR ||
  type === MULTIPLE_USERS_SAVE_ERROR ||
  type === USER_DELETE_ERROR ||
  type === USER_SAVE_ERROR ||
  type === USER_UPDATE_ERROR ||
  type === RESET_PASSWORD_ERROR;

const isAuthError = (type: string) =>
  type === CLEAR_2FA_ERROR ||
  type === AUTH_2FA_ERROR ||
  type === TURN_ON_2FA_ERROR ||
  type === GET_2FA_CODE_ERROR ||
  type === LOGIN_ERROR ||
  type === LOGOUT_ERROR ||
  type === RESET_PASSWORD_ERROR;

const isAssessmentError = (type: string) =>
  type === ASSESSMENT_LOAD_ERROR ||
  type === ASSESSMENT_LOAD_SUGGESTIONS_ERROR ||
  type === ASSESSMENT_SAVE_ERROR ||
  type === ASSESSMENT_UPDATE_STATUS_ERROR ||
  type === ASSESSMENT_UPDATE_ERROR;

const isAssessmentTypeError = (type: string) =>
  type === ASSESSMENT_TYPES_LOAD_ALL_ERROR ||
  type === ASSESSMENT_TYPES_LOAD_LABEL_FAILED;

const isCategoryError = (type: string) => type === CATEGORY_LOAD_REQUEST_FAILED;

const isCommentError = (type: string) =>
  type === COMMENT_SAVE_ERROR || type === COMMENT_DELETE_ERROR;

const isCompanyError = (type: string) =>
  type === COMPANY_UPDATE_ERROR ||
  type === COMPANY_SAVE_ERROR ||
  type === COMPANY_LOAD_ERROR ||
  type === COMPANY_ARCHIVE_ERROR ||
  type === COMPANIES_LOAD_ERROR;

const isConstantError = (type: string) => type === CONSTANTS_LOAD_ERROR;

const isDocumentError = (type: string) =>
  type === DOCUMENT_APPROVE_ERROR ||
  type === DOCUMENTS_BY_ASSESSMENT_LOAD_ERROR ||
  type === DOCUMENT_CLOSE_ERROR ||
  type === DOCUMENT_LOAD_ERROR ||
  type === DOCUMENT_SAVE_ERROR ||
  type === DOCUMENT_SET_IN_CLIENT_REVIEW_ERROR ||
  type === DOCUMENT_PP_SAVE_ERROR;

const isDocumentDraftError = (type: string) =>
  type === DOCUMENT_DRAFT_LOAD_ERROR ||
  type === DOCUMENTS_DRAFTS_LOAD_ERROR ||
  type === DOCUMENT_DRAFT_SAVE_ERROR ||
  type === DOCUMENT_DRAFT_UPDATE_ERROR;

const isFormError = (type: string) =>
  type === FORM_CLONE_ERROR ||
  type === FORM_DELETE_ERROR ||
  type === FORMS_LOAD_ERROR ||
  type === FORM_LOAD_QUESTIONS_ERROR ||
  type === FORMS_REORDER_FAILED ||
  type === FORM_SAVE_ERROR ||
  type === FORM_SET_STATUS_ERROR ||
  type === FORM_UPDATE_ERROR;

const isInterviewError = (type: string) =>
  type === INTERVIEW_LOAD_ANSWERS_ERROR ||
  type === INTERVIEW_SAVE_ERROR ||
  type === INTERVIEW_UPDATE_ERROR;

const isNotificationError = (type: string) =>
  type === NOTIFICATIONS_LOAD_ERROR || type === NOTIFICATIONS_READ_ERROR;

const isTemplateError = (type: string) =>
  type === TEMPLATE_CLONE_ERROR ||
  type === TEMPLATE_DELETE_ERROR ||
  type === TEMPLATE_LOAD_ERROR ||
  type === TEMPLATES_LOAD_ERROR ||
  type === TEMPLATE_SAVE_ERROR ||
  type === TEMPLATE_UPDATE_ERROR;

const isThreadError = (type: string) =>
  type === THREAD_LIST_LOAD_ERROR || THREAD_UPDATE_ERROR;

const isQuestionError = (type: string) =>
  type === QUESTIONS_LOAD_ERROR || type === QUESTION_TYPES_LOAD_ERROR;

const isVariableError = (type: string) =>
  type === VARIABLE_DELETE_ERROR ||
  type === VARIABLE_SAVE_ERROR ||
  type === VARIABLE_UPDATE_ERROR;

export const ErrorReducer = (
  state: ErrorState = errorInitialState,
  action: ErrorActionTypes,
): ErrorState => {
  const { type } = action;
  if (
    type === SHOW_ERROR ||
    isUserError(type) ||
    isAssessmentError(type) ||
    isAuthError(type) ||
    isAssessmentTypeError(type) ||
    isCommentError(type) ||
    isCompanyError(type) ||
    isThreadError(type) ||
    isConstantError(type) ||
    isCategoryError(type) ||
    isDocumentError(type) ||
    isDocumentDraftError(type) ||
    isFormError(type) ||
    isInterviewError(type) ||
    isQuestionError(type) ||
    isVariableError(type) ||
    isNotificationError(type) ||
    isTemplateError(type)
  ) {
    return {
      message: action.message,
      severity: action.severity,
      originalError: action.originalError,
    };
  } else {
    return state;
  }
};
