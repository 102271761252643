import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { TopNavbar } from 'src/components/TopNavbar';
import { NotificationListingTable } from 'src/features/risk-assessment/components/NotificationListingTable';
import { useAuth } from 'src/hooks/useAuth/useAuth';
import actions from 'src/store/actions';
import {
  getNotificationListingLoader,
  getNotifications,
} from 'src/store/selectors';
import { NotificationFromResponse } from 'src/store/types';
import { Tabs as TabsAntD } from 'antd';
import { Pagination } from 'src/components/Pagination';

const { TabPane } = TabsAntD;

enum Tab {
  INBOX = '1',
  ARCHIVE = '2',
}

const Tabs = styled(TabsAntD)`
  .ant-tabs-nav-list {
    .ant-tabs-tab {
      background-color: transparent;
      border: none;
      font-size: 18px;
    }

    .ant-tabs-tab-active {
      color: ${({ theme }) => theme.colors.darkBlue.main};
      text-decoration: underline;
    }
  }
`;

const CompanyListContainer = styled.div`
  height: 65%;
  overflow: auto;
  margin-bottom: 10px;
`;

const PageContentContainer = styled.div`
  margin-top: 22px;
`;

export const NotificationListingPage: FC<unknown> = () => {
  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [activeTab, setActiveTab] = useState(Tab.INBOX);
  const { loading } = useSelector(getNotificationListingLoader);

  const notifications = useSelector(getNotifications);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && user.id) {
      dispatch(
        actions.notificationListingLoadRequest({
          userId: user.id,
          page: currentPage,
          pageSize,
          archived: activeTab === Tab.ARCHIVE ?? false,
        }),
      );
      dispatch(actions.assessmentTypesLoadAllRequest());
    }
  }, [activeTab, pageSize, currentPage]);

  useEffect(() => {
    if (notifications) {
      let ids: (number | null)[] = notifications.records.map(
        (n: NotificationFromResponse) => {
          if (n.pending) {
            return n.id;
          }
          return null;
        },
      );

      ids = ids.filter((n) => !!n);

      if (ids.length) {
        dispatch(actions.notificationsMarkAsReadRequest(ids as number[]));
      }
    }
  }, [notifications]);

  return (
    <>
      <TopNavbar title={'Notifications'} prevRoute={null} />
      <PageContentContainer>
        <CompanyListContainer>
          <Tabs
            type="card"
            defaultActiveKey={Tab.INBOX}
            onChange={(key) => setActiveTab(key as Tab)}
          >
            <TabPane tab="Inbox" key={Tab.INBOX}>
              <NotificationListingTable
                notifications={notifications.records}
                currentTab={Tab.INBOX}
                loading={loading}
              />
            </TabPane>
            <TabPane tab="Archive" key={Tab.ARCHIVE}>
              <NotificationListingTable
                notifications={notifications.records}
                currentTab={Tab.ARCHIVE}
                loading={loading}
              />
            </TabPane>
          </Tabs>
          {notifications.total > 20 && (
            <Pagination
              current={currentPage}
              items={notifications.total}
              pageSize={pageSize}
              onPageChange={setCurrentPage}
              onPageSizeChange={(size) => {
                setPageSize(size);
                setCurrentPage(1);
              }}
              sizes={[20, 50, 75, notifications.total]}
              isNewStyle={true}
            />
          )}
        </CompanyListContainer>
      </PageContentContainer>
    </>
  );
};
